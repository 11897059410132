@font-face {
  font-family: "Montserrat";
  font-style: normal;
  src: url("../fonts/Montserrat-VariableFont_wght.ttf") format("truetype"); /* Replace 'font-file-name' with the actual file name and extension */
  /* Add more src lines if you have multiple font files (e.g., .woff, .woff2) */
  /* You can also specify font-weight and font-style if needed */
}
@font-face {
  font-family: "DM Sans";
  font-style: normal;
  src: url("../fonts/DMSans-VariableFont_opsz\,wght.ttf") format("truetype"); /* Replace 'font-file-name' with the actual file name and extension */
  /* Add more src lines if you have multiple font files (e.g., .woff, .woff2) */
  /* You can also specify font-weight and font-style if needed */
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  src: url("../fonts/Inter-VariableFont_slnt\,wght.ttf") format("truetype"); /* Replace 'font-file-name' with the actual file name and extension */
  /* Add more src lines if you have multiple font files (e.g., .woff, .woff2) */
  /* You can also specify font-weight and font-style if needed */
}
